<template>
  <footer class="footer">
    <Desktop
      :footer="footer"
      :translations="translations"
      :menu="menu"
      v-if="desktop === true"
      @showFormSearch="toggleForm"
    />
    <Ipad
      :footer="footer"
      :translations="translations"
      :menu="menu"
      v-if="ipad === true"
      @showFormSearch="toggleForm"
    />
    <Mobile
      :footer="footer"
      :translations="translations"
      :menu="menu"
      v-if="mobile === true"
      @showFormSearch="toggleForm"
    />
  </footer>
</template>

<script>
import { defineAsyncComponent } from "vue";

const Desktop = defineAsyncComponent(() =>
  import("@/components/Footer/Desktop.vue")
);
const Ipad = defineAsyncComponent(() => import("@/components/Footer/Ipad.vue"));
const Mobile = defineAsyncComponent(() =>
  import("@/components/Footer/Mobile.vue")
);

export default {
  components: {
    Desktop,
    Ipad,
    Mobile,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
    footer: {
      type: Array,
      required: true,
    },
    translations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      window: {
        width: 0,
      },
      desktop: true,
      ipad: false,
      mobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;

      if (this.window.width >= 1280) {
        (this.desktop = true), (this.ipad = false), (this.mobile = false);
      } else if (this.window.width >= 768 && this.window.width < 1280) {
        (this.desktop = false), (this.ipad = true), (this.mobile = false);
      } else if (this.window.width < 768) {
        (this.desktop = false), (this.ipad = false), (this.mobile = true);
      }
    },
    toggleForm() {
      this.$emit("openForm", "footer");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.footer {
  position: relative;
  padding: 50px 0 40px;
  background-color: #1c1c1c;
  color: #fff;

  @media (max-width: 1279.98px) {
    padding: 30px 0 20px;
  }
}
</style>
